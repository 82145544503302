<template>
  <svg
    width="354"
    height="125"
    viewBox="0 0 354 125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_232_626)">
      <path
        d="M106.079 106.935C101.006 112.009 96.6541 117.876 92.9792 124.244H261.029C257.328 118.025 252.932 112.247 247.781 106.935C228.835 87.9717 205.142 78.4902 176.719 78.4902C148.296 78.4902 124.471 87.9717 106.079 106.935Z"
        fill="#ED166E"
      />
      <path
        d="M177.202 29.4118C185.32 29.4118 191.902 22.8277 191.902 14.7059C191.902 6.58405 185.32 0 177.202 0C169.084 0 162.503 6.58405 162.503 14.7059C162.503 22.8277 169.084 29.4118 177.202 29.4118Z"
        fill="#F5F6FB"
      />
      <path
        d="M7.55197 125L6.80469 124.736C6.83985 124.648 10.0488 115.721 17.4337 103.073C33.3551 75.8071 70.6312 27.9954 141.412 14.0811L141.561 14.855C71.0884 28.7166 33.9705 76.326 18.1194 103.477C10.7697 116.055 7.58713 124.912 7.55197 125Z"
        fill="#F5F6FB"
      />
      <path
        d="M137.869 15.1103C135.548 15.1103 133.236 14.5914 131.073 13.5535C127.275 11.7329 124.409 8.54895 123.002 4.57343L123.75 4.30957C126.501 12.1023 135.073 16.2097 142.863 13.4568L143.126 14.2044C141.412 14.8113 139.636 15.1103 137.869 15.1103Z"
        fill="#F5F6FB"
      />
      <path
        d="M133.253 33.6336C131.855 29.6581 132.084 25.3747 133.895 21.5751C135.715 17.7755 138.897 14.9082 142.871 13.501L143.135 14.2486C135.346 17.0015 131.249 25.577 133.992 33.3697L133.244 33.6336H133.253Z"
        fill="#F5F6FB"
      />
      <path
        d="M7.80684 124.411L7.09473 124.068C10.8487 116.222 20.282 112.889 28.1241 116.645L27.7812 117.357C20.3348 113.786 11.3674 116.952 7.80684 124.411Z"
        fill="#F5F6FB"
      />
      <path
        d="M7.76293 124.393L7.05081 124.05C8.77395 120.435 8.99374 116.372 7.66622 112.598C6.32991 108.825 3.61332 105.791 0 104.067L0.34287 103.354C4.14081 105.175 7.00685 108.368 8.4135 112.335C9.81135 116.31 9.58277 120.593 7.77172 124.393H7.76293Z"
        fill="#F5F6FB"
      />
      <path
        d="M346.457 125C346.422 124.912 343.23 116.046 335.889 103.477C320.038 76.326 282.912 28.7166 212.439 14.8638L212.588 14.0898C283.369 28.0041 320.645 75.8158 336.566 103.082C343.951 115.729 347.16 124.648 347.195 124.745L346.448 125.008L346.457 125Z"
        fill="#F5F6FB"
      />
      <path
        d="M216.14 15.1103C214.373 15.1103 212.597 14.8113 210.883 14.2044L211.146 13.4568C218.936 16.2097 227.507 12.1023 230.259 4.30957L231.006 4.57343C229.609 8.54895 226.743 11.7417 222.936 13.5535C220.773 14.5826 218.461 15.1103 216.14 15.1103Z"
        fill="#F5F6FB"
      />
      <path
        d="M220.747 33.6336L219.999 33.3697C222.751 25.577 218.646 17.0015 210.856 14.2486L211.12 13.501C215.094 14.8994 218.285 17.7667 220.096 21.5751C221.916 25.3747 222.145 29.6581 220.738 33.6336H220.747Z"
        fill="#F5F6FB"
      />
      <path
        d="M346.193 124.411C344.47 120.796 341.437 118.078 337.665 116.741C333.894 115.404 329.823 115.624 326.219 117.357L325.876 116.644C329.674 114.824 333.955 114.595 337.929 116.002C341.903 117.401 345.094 120.268 346.905 124.076L346.193 124.419V124.411Z"
        fill="#F5F6FB"
      />
      <path
        d="M346.237 124.393C342.483 116.548 345.815 107.11 353.657 103.354L354 104.067C346.545 107.629 343.389 116.6 346.949 124.05L346.237 124.393Z"
        fill="#F5F6FB"
      />
    </g>
    <defs>
      <clipPath id="clip0_232_626">
        <rect
          width="354"
          height="125"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>
